import axios from 'axios'

const API = 'cedis/horarios'

const ENDPOINTS = {
    getCedis(){
        return axios.get(`${API}/listar`)
    },
    getData(idCedis){
        return axios.get(`${API}/${idCedis}/data`)
    },
    putCedisParametros(idCedis, model){
        return axios.put(`${API}/${idCedis}/update`, model)
    },
    putUpdateStatus(idCedis, model){
        return axios.put(`${API}/${idCedis}/update/status`, model)
    },
    postFranjas(model){
        return axios.post(`${API}/franjas/save`, model)
    },
    deleteFranja(idFranja){
        return axios.delete(`${API}/${idFranja}/delete`)
    },
    postDuplicar(model){
        return axios.post(`${API}/duplicar`, model)
    }
};
export default ENDPOINTS;